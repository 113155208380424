import React from "react";
import "./Hero.css";
import { ColouredHashButton } from "../Buttons/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function Hero({
  img,
  headline,
  title,
  bgTitle,
  noHeadline,
  buttonData,
}) {
  return (
    <>
      <div
        className="hero__img-container"
        style={{ backgroundImage: `url(${img})` }}
      >
        {bgTitle}
        <div>
          {buttonData && (
            <ColouredHashButton
              state_classes="hover-white"
              path={buttonData.hash}
            >
              {buttonData.text}
              <FontAwesomeIcon
                style={{ width: "24px", height: "24px" }}
                className="hero-section__arrow-icon"
                icon={faArrowDown}
              />
            </ColouredHashButton>
          )}
        </div>
      </div>
      {!noHeadline && (
        <h1 className="hero__headline">
          <div className="hero__img-wrapper">
            {title ? (
              "CAREER"
            ) : (
              <img className="image" alt="smartado-logo" src={headline} />
            )}
          </div>
        </h1>
      )}
    </>
  );
}
