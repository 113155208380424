import { ColouredHashButton } from "../../Common/Buttons/Buttons";
import "./HeroSection.css";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HeroSection({ data }) {
  return (
    <section className="hero-section__container">
      <div className="hero-section__button-container">
        <p className="hero-section__slogan">{data.title}</p>
        <ColouredHashButton state_classes="hover-white" path="#about-us">
          {data.subtitle}
          <FontAwesomeIcon
            style={{ width: "24px", height: "24px" }}
            className="hero-section__arrow-icon"
            icon={faArrowDown}
          />
        </ColouredHashButton>
      </div>
    </section>
  );
}

export default HeroSection;
