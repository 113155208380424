import { useEffect } from "react";
import AboutSection from "../../components/HomePageComponents/AboutSection/AboutSection";
import ContactSection from "../../components/HomePageComponents/ContactSection/ContactSection";
import HeroSection from "../../components/HomePageComponents/HeroSection/HeroSection";
import OfferSection from "../../components/HomePageComponents/OfferSection/OfferSection";
import "./HomePage.css";
import { useLocation } from "react-router";

function HomePage({ data }) {
  const location = useLocation();

  useEffect(() => {
    console.log(location);

    if (location) {
      const hash = location.hash?.slice(1);
      const element = window.document.getElementById(hash);
      console.log(element);
      element?.scrollIntoView();
    }
  }, [location]);

  return (
    <div className="home-page">
      <HeroSection data={data.heroSection} />
      <AboutSection data={data.aboutSection} />
      <OfferSection data={data.offerSection} />
      <ContactSection data={data.contactSection} />
    </div>
  );
}

export default HomePage;
