import React, { useEffect } from "react";
import CareerCompanyValues from "../../components/CareerComponents/CompanyValues/CareerCompanyValues";
import CareerJobOffers from "../../components/CareerComponents/JobOffers/CareerJobOffers";
import CareerDescription from "../../components/CareerComponents/SectionDescription/CareerDescription";
import Hero from "../../components/Common/Hero/Hero";
import ContactSection from "../../components/HomePageComponents/ContactSection/ContactSection";
import heroImg from "./Photo_career.webp";

export default function CareerPage({ data }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonData = {
    hash: data.hero.hash,
    text: data.hero.text,
  };

  return (
    <div>
      <Hero
        button
        img={heroImg}
        noHeadline={true}
        bgTitle={data.hero.headline}
        buttonData={buttonData}
      />
      <CareerDescription data={data.description} />
      <CareerCompanyValues data={data.company} />
      <CareerJobOffers data={data.job} />
      <ContactSection data={data.section} />
    </div>
  );
}
