import React, { useEffect } from "react";
import AboutUsAboutSection from "../../components/AboutUsComponents/AboutUs/AboutUsAboutSection";
import AboutUsCompany from "../../components/AboutUsComponents/Company/AboutUsCompany";
import Hero from "../../components/Common/Hero/Hero";
import heroImg from "./Photo_aboutus.webp";

export default function AboutUsPage({ data }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero img={heroImg} bgTitle={data.hero.headline} noHeadline />
      <AboutUsAboutSection data={data.about} />
      <AboutUsCompany data={data.company} />
    </div>
  );
}
