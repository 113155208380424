import React from "react";
import "./EurekaWhyUsSection.css";
import langs from "./assets/languages.svg";
import users from "./assets/users.svg";
import lessons from "./assets/lessons.svg";
import phone from "./assets/phone.webp";

function EurekaWhyUsSection({ data }) {
  return (
    <section id="why-us" className="eurekaWhyUs__container">
      <img className="eurekaWhyUs__phone" src={phone} alt="phone" />
      <div>
        <h2 className="eurekaWhyUs__title">{data.title}</h2>
        <p className="eurekaWhyUs__info">{data.info}</p>
        <span className="eurekaWhyUs__benefits-title">
          {data.benefits_title}
        </span>
        <ul className="eurekaWhyUs__benefits-list">
          <li>{data.benefits_list[0]}</li>
          <li>{data.benefits_list[1]}</li>
          <li>{data.benefits_list[2]}</li>
          <li>{data.benefits_list[3]}</li>
        </ul>
        <div className="eurekaWhyUs__facts-container">
          <span className="eurekaWhyUs__facts-title">{data.facts_title}</span>
          <ul className="eurekaWhyUs__facts-list">
            <li className="eurekaWhyUs__facts-list-item">
              <img
                className="eurekaWhyUs__facts-list-item-image"
                alt="langs"
                src={langs}
              />
              <p>{data.facts_list[0]}</p>
            </li>
            <li className="eurekaWhyUs__facts-list-item">
              <img
                className="eurekaWhyUs__facts-list-item-image"
                alt="users"
                src={users}
              />
              <p>{data.facts_list[1]}</p>
            </li>
            <li className="eurekaWhyUs__facts-list-item">
              <img
                className="eurekaWhyUs__facts-list-item-image"
                alt="lessons"
                src={lessons}
              />
              <p>{data.facts_list[2]}</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default EurekaWhyUsSection;
