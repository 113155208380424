import React from "react";
import "./EurekaVisionSection.css";

function EurekaVisionSection({ data }) {
  return (
    <section className="eurekaVision__container">
      <h2 className="eurekaVision__title">{data.title}</h2>
      <p className="eurekaVision__info">{data.info}</p>
    </section>
  );
}

export default EurekaVisionSection;
