import React from "react";
import book from "./assets/open_book.svg";
import headphones from "./assets/headphones.svg";
import puzzle from "./assets/puzzle.svg";
import rocket from "./assets/rocket.svg";
import "./EurekaHighlightsSection.css";

function EurekaHighlightsSection({ data }) {
  return (
    <section className="eurekaHighlights__container">
      <h2 className="eurekaHighlights__title">{data.title}</h2>
      <ul className="eurekaHighlights__list">
        <li className="eurekaHighlights__list-item">
          <img
            className="eurekaHighlights__list-item-image"
            src={book}
            alt="highlight"
          />
          <p className="eurekaHighlights__list-item-paragraph">
            {data.highlights_paragraphs[0]}
          </p>
        </li>
        <li className="eurekaHighlights__list-item">
          <img
            className="eurekaHighlights__list-item-image"
            src={headphones}
            alt="highlight"
          />
          <p className="eurekaHighlights__list-item-paragraph">
            {data.highlights_paragraphs[1]}
          </p>
        </li>
        <li className="eurekaHighlights__list-item">
          <img
            className="eurekaHighlights__list-item-image"
            src={puzzle}
            alt="highlight"
          />
          <p className="eurekaHighlights__list-item-paragraph">
            {data.highlights_paragraphs[2]}
          </p>
        </li>
        <li className="eurekaHighlights__list-item">
          <img
            className="eurekaHighlights__list-item-image"
            src={rocket}
            alt="highlight"
          />
          <p className="eurekaHighlights__list-item-paragraph">
            {data.highlights_paragraphs[3]}
          </p>
        </li>
      </ul>
    </section>
  );
}

export default EurekaHighlightsSection;
