import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p className="footer__copyright">
        Copyright © {new Date().getFullYear()}{" "}
        <span className="footer__bold-span">EOU</span> |
        <a
          target="_blank"
          href="/privacy.pdf"
          className="footer__privacy-policy-link"
        >
          Privacy policy
        </a>
      </p>
    </footer>
  );
}

export default Footer;
