import React from "react";
import "./AboutUsCompany.css";

export default function AboutUsCompany({ data }) {
  return (
    <section className="aboutUs-company___container">
      <h2 className="aboutUs-company__headline">{data.title}</h2>

      <div className="aboutUs-company___content">
        <div className="aboutUs-company__subtitle">{data.subtitle}</div>
        <p className="aboutUs-company__text">{data.text}</p>
      </div>

      <div className="aboutUs-company___content">
        <div className="aboutUs-company__subtitle">{data.subtitle1}</div>
        <div>
          <p className="aboutUs-company__text">{data.paragraphOne}</p>
          <p className="aboutUs-company__text">{data.paragraphTwo}</p>
        </div>
      </div>

      <div className="aboutUs-company___content">
        <div className="aboutUs-company__subtitle">{data.subtitle2}</div>
        <div>
          <p className="aboutUs-company__text">{data.paragraphThree}</p>
          <p className="aboutUs-company__text">{data.paragraphFour}</p>
        </div>
      </div>
    </section>
  );
}
