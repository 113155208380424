import { useRef, useState } from "react";
import { ColouredSubmitButton } from "../../Common/Buttons/Buttons";
import Modal from "../../Common/Modal/Modal";
import "./ContactSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import faX from "./faX.svg";

function ContactSection({ data }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFileName] = useState();
  const [fileError, setFileError] = useState("");
  const fileRef = useRef();

  const handleSelectFile = () => {
    console.log("select");
    const { name } = fileRef.current.files[0];
    setFileName(name);
  };

  const handleRemoveFile = (e) => {
    console.log("remove");
    e.preventDefault();
    fileRef.current.files = null;
    setFileName(() => null);
  };

  return (
    <section className="contact-section__container" id="contact">
      <h3 className="contact-section__page-title">{data.title}</h3>
      <form
        action="https://formsubmit.co/cc-managers@eou-edu.eu"
        encType="multipart/form-data"
        className="contact-section__form"
        method="POST"
      >
        <div className="contact-section__form-inner-wrapper">
          <div className="contact-section__input-wrapper">
            <input
              className="contact-section__input"
              placeholder={data.name}
              name="name"
              required
            />
            <input
              className="contact-section__input"
              placeholder={data.lastName}
              required
              name="last_name"
            />
            <input
              className="contact-section__input"
              placeholder={data.email}
              type="email"
              required
              name="email"
            />
            <input
              id="file-input"
              className="contact-section__file-input"
              type="file"
              required
              name="cv"
              ref={fileRef}
              onChange={handleSelectFile}
            />
            <label
              htmlFor="file-input"
              className="contact-section__file-input-label"
            >
              <FontAwesomeIcon icon={faPaperclip} />
              {fileName ? (
                <div className="contact-section__filename">
                  <span>{fileName}</span>{" "}
                  <img onClick={handleRemoveFile} src={faX} alt="x" />
                </div>
              ) : (
                "Attach CV (pdf/doc)"
              )}
            </label>
            {fileError && <p className="contact-section__error">{fileError}</p>}
          </div>
          <textarea
            className="contact-section__textarea"
            placeholder={data.message}
            required
            name="comment"
          />
        </div>
        <ColouredSubmitButton>{data.submit}</ColouredSubmitButton>
      </form>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </section>
  );
}

export default ContactSection;
