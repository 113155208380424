import React from "react";
import "./EurekaHeroSection.css";
import laptop from "./mockup_laptop.webp";
import elingo from "./eLingo_logo_poziom_color.svg";
import { ColouredHashButton } from "../../Common/Buttons/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

function EurekaHeroSection({ data }) {
  return (
    <section className="eurekaHero__container">
      <div className="eurekaHero__image-container">
        <img className="eurekaHero__image" src={laptop} alt="laptop" />
      </div>
      <div className="eurekaHero__content">
        <img src={elingo} alt="logo" />
        <p className="eurekaHero__content-text">{data.text}</p>
        <ColouredHashButton path="#why-us">
          {data.buttonText}
          <FontAwesomeIcon
            style={{ width: "24px", height: "24px" }}
            className="hero-section__arrow-icon"
            icon={faArrowDown}
          />
        </ColouredHashButton>
      </div>
    </section>
  );
}

export default EurekaHeroSection;
