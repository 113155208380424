import React, { useEffect } from "react";
import EurekaHeroSection from "../../components/EurekaComponents/EurekaHeroSection/EurekaHeroSection";
import EurekaHighlightsSection from "../../components/EurekaComponents/EurekaHighlightsSection/EurekaHighlightsSection";
import EurekaVisionSection from "../../components/EurekaComponents/EurekaVisionSection/EurekaVisionSection";
import EurekaWhyUsSection from "../../components/EurekaComponents/EurekaWhyUsSection/EurekaWhyUsSection";

export default function EurekaPage({ data }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <EurekaHeroSection data={data.hero} />
      <EurekaHighlightsSection data={data.highlights} />
      <EurekaVisionSection data={data.vision} />
      <EurekaWhyUsSection data={data.why_us} />
    </div>
  );
}
