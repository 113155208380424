import "./OfferSection.css";
import React from "react";
import personTyping from "./person_typing.webp";

function OfferSection({ data }) {
  return (
    <section className="offer-section__container" id="offer">
      <div className="offer-section__content">
        <h2 className="offer-section__page-title">{data.title}</h2>
      </div>
      <div className="offer-section__image-wrapper">
        <div>
          <img
            className="offer-section__image"
            src={personTyping}
            alt="person_typing"
          />
        </div>
        <div className="offer-section__slogan-wrapper">
          <p className="offer-section__slogan">{data.text}</p>
        </div>
      </div>
    </section>
  );
}

export default OfferSection;
